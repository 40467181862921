html, body{
	background-color: $secondary;
	color: #ccc;
}

*::selection {
	background-color: $primary;
	color: white;
}

#subreddit-gallery{
	width: min(90%, 1200px);
	margin-inline: auto;
}

.lightbox{
	overflow-y: hidden;
}