html {
	scroll-behavior: smooth;
	box-sizing: border-box;
}

*::selection {
	background-color: #0078D7;
	color: white;
}

* {
	padding: 0;
	margin: 0;
	font-family: inherit;
	list-style: none;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	outline: none;
}

*,
*::before,
*::after {
	box-sizing: inherit;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	-webkit-mask-position: center;
	-webkit-mask-size: contain;
	-webkit-mask-repeat: no-repeat;
	mask-position: center;
	mask-size: contain;
	mask-repeat: no-repeat;
}

body {
	font-family: sans-serif;
}

a {
	text-decoration: none;
	color: inherit;
	&:hover {
		text-decoration: none;
	}
	&:focus {
		outline: none;
	}
}

button:hover {
	cursor: pointer;
}

textarea {
	resize: none;
}

ul {
	padding: 0;
	margin: 0;
}

fieldset {
	border: none;
}

svg{
	width: 100%;
	max-height: 40vh;
	object-fit: contain;
}