@import '../../styles/variables';
@import '../../styles/vendors/emit-mediaquery';

.search{
	width: 100%;
	@include mqL{
		width: 50%;
	}
	margin: 2rem auto;
	input{
		height: unset;
		line-height: 2;
		@include mqL{
			line-height: 1.2;
		}
		&:focus{
			box-shadow: 0 0 32px 0 rgba($accent, .5);
		}
	}
}